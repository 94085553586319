


.headerdiv{
  position: relative;
width: 100%;
display: flex;
padding: 0px 0px 25px 0px;
flex-wrap: wrap;
background-color: rgb(52, 23, 13);
color: white;
}

.headertitles{
padding-left: 10px;
flex-shrink: 2;
}
.headertitles h1{
  margin: 0px;
  padding: 0px;
}
.headertitles h3{
  margin: 0px;
  padding: 0px;
}
.headertitles h1{
  margin: 0px;
  padding: 5px 0px;
}
.headertitles h3{
  margin: 0px;
  padding: 0px 0px;
  font-style: oblique;
}

.navdiv{
    margin-left: auto;
    padding: 10px 0px;

}

.headerul{
  display: flex;
  padding: 0px;
  margin: 0px;
  
    list-style-type: none;
  
}
.headerul ul{
  
  padding: 0px;
 margin: 0px;
 

}
.headerul li{
  margin: 0px 10px;
  padding: 4px;
  background-color: royalblue;
  border-radius:8px;

}

.headerul li a{
color: white;
}

.menuburger{
    display: none;
}

.divpagetitle{
  display: flex;
  justify-content: center;
}


.rounded-edge-div {
   width: 15%;
  height: 100px;
  padding: 0px;
  margin: 0px;
  z-index: 2000;
  background-color: rgb(216, 181, 136);
  border-top-right-radius: 50px;
 

}
.rounded-edge-left-div {
  color:rgb(148, 114, 70);
  width: 15%;
  height: 100px;
  padding: 0px;
  margin: 0px;
  z-index: 2000;
  background-color: rgb(52, 23, 13);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}
.rounded-edge-right-div {
  width: 70%;
  height: 100px;
  padding: 0px;
  margin: 0px;
  background-color: rgb(216, 181, 136);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
   z-index: 2000;
}

.enclosingdiv{
  position: relative;
  padding-bottom: 80px;
  background-color: rgb(216, 181, 136);
}

.strap1{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 50px;
  background-color: rgb(52, 23, 13);
  color: white;
}


.fillertext{
 opacity: 0;
}

.textpagetitle{
  color:rgb(229, 183, 122);
  
}


.subtitlepage{
  text-align: center;
}

/* .cards-container {
  display: flex;
  flex-wrap: wrap;
  margin: px;
  gap: 20px;
  justify-content: center;
} */


.cardmp-container {
  max-width: 400px; 
  margin: auto;
  padding: 8px;
  background-color: #f0f0f0; 
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
}

.buttonmp-container {
  text-align: center;
  margin-bottom: 10px;
}

.buttonmp {
  background-color: #217ae1;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  font-size: large;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonmp:hover {
  
  background-color: #0056b3;
}

.buttonmp:focus {
  
  outline: none;
}


.cardsmp {
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 0;
  overflow: hidden; 
}

.cardsmp img {
  width: 100%;
  display: block;
  border-bottom: 1px solid #eee; 
}

.cardsmp h3, .cardsmp p {
  padding: 15px; 
}

.cardsmp h3 {
  margin-top: 0;
  background-color: #91bbe7; 
  color: white;
}

.cardsmp p {
  margin-bottom: 0; 
  font-size: 14px; 
  color: #333;
}

.read-more-less {
  display: none; 
}

.aboutdiv{
min-height:100vh
}
.aboutdiv p {
font-size: 20px;
padding: 0 80px;
font-weight: bolder;
}


.footerdiv {
  position: fixed;
  bottom: 0;
  left: 0; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; 
  background: transparent;
  padding: 10px 0;
}

.footerdiv a {
  padding: 5px;
  display: inline-flex;
  align-items: center;
}

.footerdiv img {
  max-width: 80px;
  height: auto;
}

.githublogo{
  width: 30px;
}
.maillogo{
  width: 40px;
}
.linkedlogo{
  width: 80px;
}

.read-more-less {
  display: inline-block;
  padding: 5px 10px;
  background-color: none;
  color:#0056b3;
  font-size: 15px;
  border: none;
  cursor: pointer;
}


.nodisplay-more {
  display: none;
}

.contact-form {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical; 
}

.submit-btn {
  width: 100%;
  background-color: #0056b3;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-btn:hover {
  background-color: #004494;
}

.contactdiv{
  min-height: 100vh;
}

.rlandMbtn{
  border-radius: 5px;
  text-align: center;
     margin-bottom: 10px;
  cursor:pointer;

  
}

.container-carousel {
  display: block;
  margin-bottom: 50px;
  /* width: 90% */
  

}
.my-carousel {
  display: flex;
 margin: 0 auto;
  max-width: 600px
  
}

.my-carousel img {
  width: 100%;
  object-fit: cover;
  margin: 0 auto;
  background-color: black;
}

.cards {
  background: #ffffff;
  margin: 0 auto;
}



.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cards {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; 
  margin-bottom: 20px;
}

.cards img {
  width: 100%;
  height: auto;
  display: block;
}

.cards h3 {
  margin: 0;
  padding: 15px;
  background: #007BFF;
  color: #fff;
  font-size: 18px;
}

.cards h3 a {
  color: #ffffff;
  text-decoration: none;
}

.cards p {
  padding: 10px 15px;
  margin: 0;
  color: #333;
  font-size: 18px;
}

.cards p:first-of-type {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.cards p:last-child {
  text-align: center;
  background: #f8f8f8;
  margin: -15px -15px 0;
  padding: 10px;
  color: #007BFF;
  font-weight: bold;
}










@media screen and (max-width: 1000px) {
  .rounded-edge-div {
    width: 20%;
   height: 80px;
   border-top-right-radius: 40px;
   
  
 }
 .rounded-edge-left-div {
   color:rgb(148, 114, 70);
   width: 20%;
   height: 80px;
   border-top-left-radius: 40px;
   border-bottom-left-radius: 40px;
   border-top-right-radius: 40px;
   border-bottom-right-radius: 40px;

 }
 .rounded-edge-right-div {
   width: 60%;
   height: 80px;
   border-top-left-radius: 40px;
   border-bottom-left-radius: 40px;

 }

 .strap1{
  height: 40px;

}

 }
@media screen and (max-width: 835px) {
  .aboutdiv p {
    font-size: 17px;
    padding: 0 40px;
    font-weight: bolder;
    }

 }
@media screen and (max-width: 710px) {
  .rounded-edge-div {
    width: 25%;
   height: 60px;
   border-top-right-radius: 30px;
   
  
 }
 .rounded-edge-left-div {
   color:rgb(148, 114, 70);
   width: 30%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   border-top-right-radius: 30px;
   border-bottom-right-radius: 30px;

 }
 .rounded-edge-right-div {
   width: 45%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;

 }

 .strap1{
  height: 30px;

}

 }
@media screen and (max-width: 553px) {
  .rounded-edge-div {
    width: 10%;
   height: 60px;
   border-top-right-radius: 30px;
   
  
 }
 .rounded-edge-left-div {
   color:rgb(148, 114, 70);
   width: 35%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   border-top-right-radius: 30px;
   border-bottom-right-radius: 30px;

 }
 .rounded-edge-right-div {
   width: 55%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;

 }

 .strap1{
  height: 30px;

}

 }
@media screen and (max-width: 550px) {
img{
  max-width: unset;
  width: 100%;
}

 }
@media screen and (max-width: 508px) {
  .headerdiv{
 
  flex-wrap: nowrap;
  
  }

  .navdiv{
    padding: 0px;
    margin-left: 40px;
    
  }
  .menuburger{
    display:block;
    margin: 0px;
    padding: 0px;
    font-size: 30px;
    margin-right: 10px;
   
  }
  .headerul{
    display: none; 
   }

 .navdiv:hover .headerul{
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 3000;
  top: 45px;
  right: 2px;
  

 }
 .navdiv:hover .headerul li{
background-color: royalblue;
padding: 5px 10px;
margin: 5px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
  

 }

 .aboutdiv p {
  font-size: 18px;
  padding: 0 10px;
  font-weight: bolder;
  }
}
@media screen and (max-width: 415px) {
  .rounded-edge-div {
    width: 15%;
   height: 60px;
   border-top-right-radius: 30px;
   
  
 }
 .rounded-edge-left-div {
   color:rgb(148, 114, 70);
   width: 50%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   border-top-right-radius: 30px;
   border-bottom-right-radius: 30px;

 }
 .rounded-edge-right-div {
   width: 35%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;

 }

 .strap1{
  height: 30px;

}

 }
@media screen and (max-width: 311px) {
  .rounded-edge-div {
    width: 15%;
   height: 60px;
   border-top-right-radius: 30px;
   
  
 }
 .rounded-edge-left-div {
   color:rgb(148, 114, 70);
   width: 55%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   border-top-right-radius: 30px;
   border-bottom-right-radius: 30px;

 }
 .rounded-edge-right-div {
   width: 30%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;

 }

 .strap1{
  height: 30px;

}

.navdiv{
  padding: 0px;
  margin-left: 25px;
  
}

 }
@media screen and (max-width: 247px) {
  .rounded-edge-div {
    width: 10%;
   height: 60px;
   border-top-right-radius: 30px;
   
  
 }
 .rounded-edge-left-div {
   color:rgb(148, 114, 70);
   width: 65%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
   border-top-right-radius: 30px;
   border-bottom-right-radius: 30px;

 }
 .rounded-edge-right-div {
   width: 25%;
   height: 60px;
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;

 }

 .strap1{
  height: 30px;

}




 }
@media screen and (max-width: 235px) {
h1{
  font-size: 1.5rem;
}
h2{
  font-size: 1.0rem;
}
h3{
  font-size: 0.8rem;
}

.navdiv{
  padding: 0px;
  margin-left: auto;
  
}


.menuburger{
 
padding: 2px 2px;
  font-size: 20px;
 
}

 }
@media screen and (max-width: 164px) {
h1{
  font-size: 1.2rem;
}
h2{
  font-size: 0.8rem;
}
h3{
  font-size: 0.5rem;
}

.menuburger{
 
  padding: 5px;
  font-size: 20px;
 
}

 }
@media screen and (max-width: 600px) {
  .my-carousel {
    display: flex;
    max-width: unset;
    width: 100%;
   padding:  5px;
    
  }
  

 }





